<template>
  <div class="flex h-full items-center justify-center bg-indigo-500">
    <arrow-path-icon class="h-10 w-10 animate-spin text-white" />
  </div>
</template>

<script>
import { ArrowPathIcon } from "@heroicons/vue/24/outline";
export default {
  components: { ArrowPathIcon },
  setup() {
    return {};
  },
};
</script>
