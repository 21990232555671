<template>
  <div
    class="z-50 w-80 rounded-md border border-slate-300 bg-white p-4 shadow-md transition-opacity duration-500"
    :class="{ 'opacity-0': opacity == 0, 'opacity-100': opacity == 1 }"
  >
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-1">
        <CheckCircleIcon
          v-if="severity == 'success'"
          class="h-7 w-7 text-green-600"
        />
        <ExclamationCircleIcon
          v-else-if="severity == 'warning'"
          class="h-7 w-7 text-yellow-600"
        />
        <InformationCircleIcon
          v-else-if="severity == 'info'"
          class="h-7 w-7 text-indigo-600"
        />
        <XCircleIcon v-else class="h-7 w-7 text-rose-600" />
        <h3>{{ t(`toast.headings.${severity}`) }}</h3>
      </div>
      <button
        class="rounded-full p-1 hover:bg-slate-300"
        type="button"
        @click="close()"
      >
        <XMarkIcon class="h-5 w-5" />
      </button>
    </div>
    <div
      class="mt-2 rounded-md px-3 py-2"
      :class="{
        'bg-green-100': severity == 'success',
        'bg-yellow-100': severity == 'warning',
        'bg-indigo-100': severity == 'info',
        'bg-rose-100': severity == 'error',
      }"
    >
      <p v-if="severity == 'success'">
        {{ t(`toast.successes.${content}`) }}
      </p>
      <p v-else-if="severity == 'error'">
        {{ t(`toast.errors.${content}`) }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
export default {
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
    XMarkIcon,
  },
  props: {
    content: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: Number,
    },
    life: {
      default: 0,
      required: false,
      type: Number,
    },
    severity: {
      validator(value) {
        return ["error", "info", "success", "warning"].includes(value);
      },
    },
  },
  emits: ["destroy"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const opacity = ref(0);

    const close = () => {
      opacity.value = 0;
      setTimeout(() => emit("destroy", props.id), 500);
    };

    const timer = (life) => {
      setTimeout(close, life);
    };

    onMounted(() => {
      setTimeout(() => {
        opacity.value = 1;
      }, 0);
      if (props.life) {
        timer(props.life);
      }
    });

    return {
      close,
      opacity,
      t,
    };
  },
};
</script>
