import { db } from "@/firebase";
import { collection, getDocs } from "firebase/firestore";

const getDefaultState = () => {
  return {
    roles: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async getRoles({ commit }) {
    try {
      commit("wait");
      const querySnapshot = await getDocs(collection(db, "roles"));
      const roles = querySnapshot.docs.map((doc) => {
        const role = doc.data();
        role.id = doc.id;
        return role;
      });
      commit("setRoles", roles);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setRoles(state, roles) {
    state.roles = roles;
    state.waiting = false;
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const role = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default role;
